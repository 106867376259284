import TopProgressBar from "@/src/components/Common/Main/TopProgressBar";
import ToastContainer from "@/src/components/Common/Toast/ToastContainer";
import "@/styles/globals.css";
import { appWithTranslation } from "next-i18next";
import Head from "next/head";
import { useRouter } from "next/router";
import "nprogress/nprogress.css"; //styles of nprogress
import { useEffect } from "react";

function App({ Component, pageProps }) {
  const router = useRouter();
  useEffect(() => {
    let dir = router.locale === "fa" ? "rtl" : "ltr";
    let lang = router.locale == "fa" ? "fa" : "en";
    document.querySelector("html").setAttribute("dir", dir);
    document.querySelector("html").setAttribute("lang", lang);
  }, [router.locale]);

  const site = "https://siraaf.com";
  return (
    <>
      <TopProgressBar />
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=5.0, minimum-scale=1.0" />
        <meta name="msapplication-TileColor" content="#da532c"></meta>
        <meta name="robots" content="index, follow"></meta>
        <meta name="theme-color" content="#0081CA"></meta>
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap"
          rel="stylesheet"
        ></link>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/fav/apple-touch-icon.png"
        ></link>
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/fav/favicon-32x32.png"
        ></link>
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/fav/favicon-32x32.png"
        ></link>
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/fav/favicon-16x16.png"
        ></link>
        <link rel="manifest" href="/fav/site.webmanifest"></link>
        <link
          rel="mask-icon"
          href="/fav/safari-pinned-tab.svg"
          color="#5bbad5"
        ></link>
        <link rel='alternate' hrefLang='x-default' href={site+router.asPath} />
        <link rel='alternate' hrefLang='en' href={site+router.asPath} />
        <link rel='alternate' hrefLang='fa' href={site+'/fa'+router.asPath} />
        <link rel='canonical' href={site+(router.locale=="en"?"":"/"+router.locale)+router.asPath} />

        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:site" content="@siraaf"/>
        <meta name="twitter:image" content="/fav/favicon-32x32.png"/>

        <meta property="og:site_name" content="Siraaf"/>
        <meta property="og:url" content={site+(router.locale=="en"?"":"/"+router.locale)+router.asPath}/>
        <meta property="og:type" content="website"/>
        <meta property="og:image" content="/fav/favicon-32x32.png"></meta>

        <script type="application/ld+json" dangerouslySetInnerHTML={{__html:`
          {
            "@context": "https://schema.org/",
            "@type": "WebSite",
            "name": "Siraaf",
            "url": "https://siraaf.com",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://siraaf.com/search?search_key={search_term_string}",
              "query-input": "required name=search_term_string"
            }
          }
        `}}></script>
      </Head>
      <Component {...pageProps} />
      <ToastContainer>salam</ToastContainer>
    </>
  );
}

export default appWithTranslation(App);
